import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA
export default class BodegasService {
    get (params) {
        return http.get(`${baseUrl}/bodegas/option-select`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    getBodegasSinResponsable () {
        return http.get(`${baseUrl}/bodegas/bodegas-sin-responsable`, {
            headers: {
                loading: false
            }
        })
    }

    getResponsables (params) {
        return http.get(`${baseUrl}/bodegas/responsables`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    getSecundarios (params) {
        return http.get(`${baseUrl}/bodegas/responsables-secundarios`, {
            params,
            headers: {
                loading: false
            }
        })
    }

    putResponsables (id, payload) {
        return http.put(`${baseUrl}/bodegas/responsable/${id}`, payload, {
            headers: {
                loading: false
            }
        })
    }

    asignarResponsable (payload) {
        return http.post(`${baseUrl}/bodegas/responsable`, payload, {
            headers: {
                loading: false
            }
        })
    }
}
