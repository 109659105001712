<template>
    <section>
      <div class="w-full">
        <div class="flex items-center mt-4">
          <svg style="width:18px;height:18px" viewBox="0 0 24 24">
              <path fill="rgba(113, 128, 150, var(--tw-text-opacity))" d="M6 19H8V21H6V19M12 3L2 8V21H4V13H20V21H22V8L12 3M8 11H4V9H8V11M14 11H10V9H14V11M20 11H16V9H20V11M6 15H8V17H6V15M10 15H12V17H10V15M10 19H12V21H10V19M14 19H16V21H14V19Z" />
          </svg>
          <p class="text-gray-600 font-bold ml-2">Configuración Responsable Bodegas Sugeridos</p>
        </div>
        <div class="lg:flex lg:justify-between w-full py-4 mt-4 items-center">
          <div v-if="$can('pharmasan.logistica.configuracion.bodegas.access')" class="flex gap-x-4">
            <Button class="my-2 lg:my-0" @click="displayModal = true" label="Asignar bodega +" />
            <Button class="my-2 lg:my-0 bg-gray-300 border-0 text-gray-600 font-bold" @click="displayModalResponsableSecundario = true" label="Asignar responsable secundario +" />
          </div>
          <div class="flex w-auto">
              <Dropdown
                  v-model="filtros.bodega"
                  :options="bodegas"
                  :filter="true"
                  optionLabel="bodega"
                  optionValue="WhsCode"
                  placeholder="Seleccione una opción"
                  class="rounded w-full border mr-2"
                  @change="getResponsables"
              />
            <Button class="lg:hidden w-12 p-2" @click="limpiar">
              <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                  <path fill="white" d="M22,3H7C6.31,3 5.77,3.35 5.41,3.88L0,12L5.41,20.11C5.77,20.64 6.31,21 7,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M19,15.59L17.59,17L14,13.41L10.41,17L9,15.59L12.59,12L9,8.41L10.41,7L14,10.59L17.59,7L19,8.41L15.41,12" />
              </svg>
            </Button>
            <Button class="hidden lg:flex w-full" @click="limpiar" icon="search" label="Limpiar" />
          </div>
        </div>
        <Dialog v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <template #header>
            <div class="flex jusstify-between">
              <h3 class="font-bold">Asignar bodega</h3>
            </div>
          </template>
          <div class="mb-4">
            <label class="text-xs font-bold" for="">Empleado</label>
            <Dropdown
              v-model="infoEmpleado"
              :options="personas"
              optionLabel="full_name"
              placeholder="Seleccione una opción"
              class="rounded w-full border"
              :filter="true"
            />
          </div>
          <div>
            <label class="text-xs font-bold" for="">Bodega</label>
            <Dropdown
                v-model="infoBodega"
                :options="bodegasSinResponsable"
                :filter="true"
                optionLabel="bodega"
                placeholder="Seleccione una opción"
                class="rounded w-full border"
            />
          </div>
          <template #footer>
            <div  class="w-full flex justify-center gap-4">
              <Button label="CANCELAR"  @click="closeModal" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
              <Button label="ASIGNAR"  @click="asignarResponsable(true)" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" autofocus />
            </div>
          </template>
        </Dialog>
        <Dialog v-model:visible="displayModalResponsableSecundario" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <template #header>
            <div class="flex jusstify-between">
              <h3 class="font-bold">Asignar bodega responsable secundario</h3>
            </div>
          </template>
          <div class="mb-4">
            <label class="text-xs font-bold" for="">Empleado</label>
            <Dropdown
              v-model="infoEmpleado"
              :options="personas"
              optionLabel="full_name"
              placeholder="Seleccione una opción"
              class="rounded w-full border"
              :filter="true"
            />
          </div>
          <div>
            <label class="text-xs font-bold" for="">Bodega</label>
            <Dropdown
                v-model="infoBodega"
                :options="bodegas"
                :filter="true"
                optionLabel="bodega"
                placeholder="Seleccione una opción"
                class="rounded w-full border"
            />
          </div>
          <template #footer>
            <div  class="w-full flex justify-center gap-4">
              <Button label="CANCELAR"  @click="closeModalCrearResponsableSecundario" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
              <Button label="ASIGNAR"  @click="asignarResponsable(false)" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" autofocus />
            </div>
          </template>
        </Dialog>
        <Dialog :closable="false" v-model:visible="displayModalListaResponsablesSecundarios" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
          <template #header>
            <div class="flex justify-between items-center w-full">
              <div>
                <h3 class="font-bold">Responsables secundarios</h3>
              </div>
              <div>
                <Button @click="closeModalListaResponsablesSecundarios" class="bg-transparent border-0 text-gray-800" icon="pi pi-times" />
              </div>
            </div>
          </template>
          <div v-if="responsablesSecundarios.length">
            <div v-for="(res, i) in responsablesSecundarios" :key="i">
              <div class="flex justify-between my-2">
                <div class="w-full flex items-center text-xs">
                  <p class="font-bold">{{ res.Id }}</p>
                  <p class="mx-4">{{ res.userResponsable }}</p>
                </div>
                <Button @click="eliminarResponsableSecundario(res), res" class="p-button-danger" icon="pi pi-trash" />
              </div>
            </div>
          </div>
          <div v-else>
            <p>Esta bodega no cuenta con responsables secundarios</p>
          </div>
        </Dialog>
        <Dialog v-model:visible="displayModalEditar" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
          <template #header>
            <div class="flex jusstify-between">
              <h3 class="font-bold">Asignar bodega</h3>
            </div>
          </template>
          <div class="mb-4">
            <label class="text-xs font-bold" for="">Empleado</label>
            <Dropdown
              v-model="dataEditar.infoEmpleado"
              :options="personas"
              optionLabel="full_name"
              placeholder="Seleccione una opción"
              class="rounded w-full border"
              :filter="true"
            />
          </div>
          <div>
            <label class="text-xs font-bold" for="">Bodega</label>
            <p>{{ dataEditar.infoBodega.bodega }}</p>
          </div>
          <template #footer>
            <div  class="w-full flex justify-center gap-4">
              <Button label="CANCELAR"  @click="closeModalEditar" class="bg-gray-300 hover:bg-gray-300 text-gray-800 hover:text-gray-800 border-0"/>
              <Button label="RE-ASIGNAR"  @click="editarResponsable" class="bg-green-400 hover:bg-green-400 text-white hover:text-white border-0" autofocus />
            </div>
          </template>
        </Dialog>
        <DataTable class="p-datatable-sm" :value="responsables" responsiveLayout="scroll">
          <template #empty>
            Esta bodega no cuenta con ningún responsable por favor asigne uno
          </template>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="Id" header="ID"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="userResponsable" header="Responsable"></Column>
          <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="bodega" header="Bodega"></Column>
          <Column
          v-if="$can('pharmasan.logistica.configuracion.bodegas.access')"
            header="Acciones"
            headerClass="lg:text-xs"
            bodyClass="lg:text-xs"
          >
            <template #body="{data}">
              <div class="flex gap-x-4">
                <Button @click="openModalEditar(data)" icon="pi pi-pencil" />
                <Button @click="openModalListaResponsableSecundario(data.WhsCode)" icon="pi pi-list" class="p-button-warning" />
              </div>
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="totalItemsCount"
          :rowsPerPageOptions="[2,10,20,30]"
          @page="onPage($event)"
        />
      </div>
    </section>
  </template>
  <script>
    import BodegasService from '../../../../services/bodegas.service'
    import PersonasService from '../../../../services/personas.service'
    import Swal from 'sweetalert2'
    import { onMounted, ref, computed } from 'vue'
    import dayjs from 'dayjs'
    export default {
    name: 'ConfiguracionLogisticaPharmasanResponsablesBodegas',
    setup () {
      // Services
      const _BodegasService = ref(new BodegasService())
      const _PersonasService = ref(new PersonasService())
      // References
      const bodegas = ref([])
      const bodegasSinResponsable = ref([])
      const personas = ref([])
      const usuarios = ref([])
      const responsables = ref([])
      const displayModal = ref(false)
      const displayModalResponsableSecundario = ref(false)
      const offset = ref(0)
      const page = ref(0)
      const limit = ref(10)
      const totalItemsCount = ref(0)
      const displayModalEditar = ref(false)
      const displayModalListaResponsablesSecundarios = ref(false)
      const infoEmpleado = ref({})
      const infoBodega = ref({})
      const responsablesSecundarios = ref([])
      const dataModalEditar = ref({})
      const expandedRowGroups = ref()
      const dataEditar = ref({
        infoEmpleado: {},
        infoBodega: {}
      })
      const filtros = ref(
      {
        bodega: ''
      })
      // Computed
      const params = computed(() => {
        return {
          offset: offset.value,
          page: page.value,
          limit: limit.value,
          bodega: filtros.value.bodega
        }
      })
      const paramsRespon = computed(() => {
        return {
          WhsCode: ''
        }
      })
      // Methods
      const getBodegas = () => {
        _BodegasService.value.get().then(({ data }) => {
          bodegas.value = data
        })
      }
      const getResponsables = () => {
        _BodegasService.value.getResponsables(params.value).then(({ data }) => {
          totalItemsCount.value = data.count
          responsables.value = data.rows
        })
      }
      const getBodegasSinResponsable = () => {
        _BodegasService.value.getBodegasSinResponsable().then(({ data }) => {
          bodegasSinResponsable.value = data
        })
      }
      const limpiar = () => {
        filtros.value.bodega = ''
        getResponsables()
      }
      const getPersonas = () => {
        _PersonasService.value.get().then(({ data }) => {
          personas.value = data
          data.map(a => {
            usuarios.value.push(a.usuario)
          })
        })
      }
      const asignarResponsable = (responsablePrincipal) => {
        const object = {
          userResponsable: infoEmpleado.value.full_name,
          WhsCode: infoBodega.value.WhsCode,
          user_id: infoEmpleado.value.usuario.id,
          responsablePrincipal
        }
        const parametrosSecundarios = params.value
        parametrosSecundarios.bodega = infoBodega.value.WhsCode
        if (responsablePrincipal) {
          _BodegasService.value.asignarResponsable(object).then(({ data }) => {
                Swal.fire({
                  title: 'Guardado',
                  icon: 'success',
                  text: 'Asignación de bodega guardada con exito',
                  confirmButtonText: 'Ok'
                }).then(() => {
                  params.value.bodega = ''
                  getResponsables()
                  closeModal()
                  closeModalCrearResponsableSecundario()
                })
              })
        } else {
          _BodegasService.value.getResponsables(parametrosSecundarios).then(({ data }) => {
            if (data.rows.length) {
              if (data.rows[0].user_id === infoEmpleado.value.usuario.id) {
                Swal.fire(
                  'Alerta',
                  'No puedes asignar este usuario como responsable secundario, ya que se enceuntra registrado como responsable principal de esta bodega',
                  'warning'
                )
              } else {
                _BodegasService.value.asignarResponsable(object).then(({ data }) => {
                  Swal.fire({
                    title: 'Guardado',
                    icon: 'success',
                    text: 'Asignación de bodega guardada con exito',
                    confirmButtonText: 'Ok'
                  }).then(() => {
                    params.value.bodega = ''
                    getResponsables()
                    closeModal()
                    closeModalCrearResponsableSecundario()
                  })
                })
              }
            } else {
              Swal.fire({
                title: 'Advertencia',
                text: 'Esta bodega no cuenta con responsable principal, deseas asignar este usuario como responsable principal?',
                showDenyButton: true,
                denyButtonText: 'Cancelar',
                confirmButtonText: 'Ir a asignar'
              }).then((result) => {
                if (result.isConfirmed) {
                  displayModalResponsableSecundario.value = false
                  displayModal.value = true
                } else if (result.isDenied) {
                  infoBodega.value = {}
                }
              })
            }
          })
        }
      }
      const editarResponsable = () => {
        const object = {
          userResponsable: dataEditar.value.infoEmpleado.full_name,
          user_id: dataEditar.value.infoEmpleado.usuario.id,
          deleted_at: ''
        }
        _BodegasService.value.putResponsables(dataModalEditar.value.Id, object).then(({ data }) => {
          if (data.error) {
            console.error('Error al cambiar el responsable de esta bodega, por favor intente nuevamente')
          } else {
            Swal.fire({
              title: 'Guardado',
              icon: 'success',
              text: 'Re-asignación de bodega guardada con exito',
              confirmButtonText: 'Ok'
            }).then(() => {
              getResponsables()
              closeModalEditar()
            })
          }
        })
      }
      const eliminarResponsableSecundario = (dataEliminado) => {
        Swal.fire({
          title: 'Esta seguro de eliminar este responsable?',
          showDenyButton: true,
          denyButtonText: 'Cancelar',
          confirmButtonText: 'Eliminar'
        }).then((result) => {
          if (result.isConfirmed) {
            const object = {
              userResponsable: dataEliminado.userResponsable,
              user_id: dataEliminado.user_id,
              deleted_at: dayjs()
            }
            _BodegasService.value.putResponsables(dataEliminado.Id, object).then(({ data }) => {
              if (data.error) {
                console.error('Error al eliminar el responsable de esta bodega, por favor intente nuevamente')
              } else {
                Swal.fire({
                  title: 'Responsable eliminado',
                  icon: 'success',
                  text: 'Responsable eliminado con exito',
                  confirmButtonText: 'Ok'
                }).then(() => {
                  getResponsables()
                  displayModalListaResponsablesSecundarios.value = false
                })
              }
            })
          } else if (result.isDenied) {
            Swal.fire('Responsable no eliminado', '', 'info')
          }
        })
      }
      const openModalEditar = (data) => {
        dataModalEditar.value = data
        displayModalEditar.value = true
        bodegas.value.map(a => {
          if (a.bodega === data.bodega) {
            dataEditar.value.infoBodega = a
          }
        })
        for (const i of personas.value) {
          if (JSON.parse(JSON.stringify(i.usuario))) {
            if (JSON.parse(JSON.stringify(i.usuario.id)) === parseInt(data.user_id)) {
              dataEditar.value.infoEmpleado = i
            }
          }
        }
      }
      const closeModalEditar = (data) => {
        dataEditar.value = {
          infoEmpleado: {},
          infoBodega: {}
        }
        dataModalEditar.value = {}
        displayModalEditar.value = false
      }
      const closeModal = () => {
        displayModal.value = false
        infoEmpleado.value = {}
        infoBodega.value = {}
        displayModalResponsableSecundario.value = false
      }
      const openModalListaResponsableSecundario = (WhsCode) => {
        paramsRespon.value.WhsCode = WhsCode
        _BodegasService.value.getSecundarios(paramsRespon.value).then(({ data }) => {
          responsablesSecundarios.value = data
          displayModalListaResponsablesSecundarios.value = true
        })
      }
      const closeModalListaResponsablesSecundarios = () => {
        responsablesSecundarios.value = []
        displayModalListaResponsablesSecundarios.value = false
      }
      const closeModalCrearResponsableSecundario = () => {
        displayModalResponsableSecundario.value = false
        infoEmpleado.value = {}
        infoBodega.value = {}
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        getResponsables(params.value)
      }
      onMounted(() => {
        getBodegas()
        getResponsables()
        getPersonas()
        getBodegasSinResponsable()
      })
      return {
        bodegas,
        responsables,
        offset,
        page,
        limit,
        filtros,
        totalItemsCount,
        onPage,
        displayModal,
        personas,
        asignarResponsable,
        infoEmpleado,
        infoBodega,
        closeModal,
        getResponsables,
        limpiar,
        usuarios,
        bodegasSinResponsable,
        displayModalEditar,
        openModalEditar,
        dataEditar,
        closeModalEditar,
        editarResponsable,
        expandedRowGroups,
        displayModalResponsableSecundario,
        displayModalListaResponsablesSecundarios,
        openModalListaResponsableSecundario,
        responsablesSecundarios,
        closeModalListaResponsablesSecundarios,
        eliminarResponsableSecundario,
        closeModalCrearResponsableSecundario
      }
    }
  }
  </script>

  <style scoped>
    ::v-deep(.p-tieredmenu .p-menuitem a) {
      background-color: green !important;
    }
    .on {
      border-bottom: 3px solid #1C3FAA;
      width: 12%;
      text-align: center;
    }
    .off {
      width: 12%;
      text-align: center;

    }
    ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  :global(.swal2-title) {
    line-height: initial !important;
  }
  </style>
