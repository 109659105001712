import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_TECNICA
export default class PersonasService {
    get () {
        return http.get(`${baseUrl}/personas/listar-personas`, {
            headers: {
                loading: false
            }
        })
    }
}
